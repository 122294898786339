import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import ForensicPageTemplate from '../components/ForensicPageTemplate';
import Layout from '../components/Layout';

const ForensicPage = (props) => {
  const {
    data: {
      markdownRemark: {
        frontmatter: {
          title,
          meta_title,
          meta_description,
          heading,
          description,
          offerings,
        },
      },
    },
  } = props;

  return (
    <Layout>
      <ForensicPageTemplate
        title={title}
        meta_title={meta_title}
        meta_description={meta_description}
        heading={heading}
        description={description}
        offerings={offerings}
      />
    </Layout>
  );
};

ForensicPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ForensicPage;

export const forensicPageQuery = graphql`
  query ForensicPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        heading
        description
        offerings {
          details {
            heading
            items
          }
        }
      }
    }
  }
`;
